import * as React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterSort, LocationLanguageContextType, StrapiTemplateSearchResult, StrapiTemplateCategory, TemplateCategory } from '../typings';
import { defaultLocation, Languages } from '../utils/getAllSupportedLanguages';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import SearchResultTable from '../components/SearchResultTable';
import Seo from '../components/Seo';
import FilterSortDialogMobile from '../components/FilterSortDialogMobile';
import FilterSortFields from '../components/FilterSortFields';
import { filterTemplates } from '../utils/filterTemplates';
import { sortTemplates } from '../utils/sortTemplates';
import { getTemplateCategoryByLocale } from '../utils/getFilterOptions';
import { setGTMEvent } from '../utils/setGAEvent';
import { matchesMobile } from '../utils/mediaQuery';

const uniq = require('lodash/uniqBy');
const sort = require('lodash/sortBy');

declare const window: any;

interface SearchLayoutProps {
  searchQuery: string;
}

const SearchLayout: React.FC<SearchLayoutProps> = ({ searchQuery }) => {
  const [searchResult, setSearchResult] = React.useState<{
    originalTemplates: StrapiTemplateSearchResult[];
    filteredSortedTemplates: StrapiTemplateSearchResult[];
  }>({
    originalTemplates: [],
    filteredSortedTemplates: []
  });
  const [isOpenFilterSortDialog, setIsOpenFilterSortDialog] = React.useState(false);

  const [filterSortValue, setFilterSortValue] = React.useState<FilterSort>({
    filters: {
      language: [],
      categories: [],
      trades: []
    },
    sort: 'relevance'
  });

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const country = locationLanguage?.country ?? defaultLocation;
  React.useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      window.__LUNR__.__loaded.then((lunr: any) => {
        let refs = lunr.en.index.search(`${searchQuery}^100 ${searchQuery}*^10`);
        refs = uniq(sort(refs, 'score').reverse(), 'ref');
        const templates = refs.map((ref: any) => lunr.en.store[ref.ref]);
        let filteredSortedTemplates = filterTemplates(templates, filterSortValue.filters);
        filteredSortedTemplates = sortTemplates(filteredSortedTemplates, filterSortValue.sort);
        setSearchResult({
          originalTemplates: templates,
          filteredSortedTemplates
        });
        setGTMEvent('search_term', 'search', searchQuery);
      });
    }
    if (!searchQuery) setSearchResult({ originalTemplates: [], filteredSortedTemplates: [] });
  }, [searchQuery, filterSortValue.filters, filterSortValue.sort, country]);

  const allCategories: StrapiTemplateCategory[] = [];
  const allTrades: StrapiTemplateCategory[] = [];
  const allTemplateLanguages: StrapiTemplateCategory[] = [];

  // template may be published without any types tagged
  let filterCagetories: TemplateCategory[] | null = [];
  let filterTrades: TemplateCategory[] | null = [];
  let filterLanguages: TemplateCategory[] | null = [];

  if (searchResult.originalTemplates.length > 0) {
    for (const template of searchResult.originalTemplates) {
      template.categories?.map((category) => allCategories.push(category));
      template.trades?.map((trade) => allTrades.push(trade));
      if (template.template_language) {
        allTemplateLanguages.push(template.template_language);
      }
    }
    filterCagetories = getTemplateCategoryByLocale(allCategories, language);
    filterTrades = getTemplateCategoryByLocale(allTrades, language);
    filterLanguages = getTemplateCategoryByLocale(allTemplateLanguages, language);
  }

  const openFilterSortDialog = () => {
    setIsOpenFilterSortDialog(true);
  };
  const handleFilterSortDialogClose = (value: any) => {
    if (value) {
      setFilterSortValue(value);
    }
    setIsOpenFilterSortDialog(false);
  };
  const handleFilterSortChange = (filterValue: FilterSort) => {
    setFilterSortValue(filterValue);
  };
  const isMobile = matchesMobile();
  return (
    <>
      <Seo title='Search' isTemplatePage={false} />
      <Container maxWidth='xl' style={{ marginTop: '1.5rem' }}>
        <Grid container spacing={3}>
          {!isMobile && (
            <Grid item xs={12} sm={3}>
              <Paper style={{ padding: '1rem 1rem' }}>
                <FilterSortFields
                  filterSortObj={filterSortValue}
                  onFilterSortChange={handleFilterSortChange}
                  isBrowsePage={false}
                  languages={filterLanguages}
                  categories={filterCagetories}
                  trades={filterTrades}
                />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} sm={9}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h5' paragraph style={{ marginBottom: '0' }}>
                {searchQuery}
              </Typography>
              {isMobile ? (
                <IconButton aria-label='search' onClick={openFilterSortDialog}>
                  <FilterListIcon />
                </IconButton>
              ) : null}
            </div>
            {searchResult ? (
              <div>
                <SearchResultTable searchResults={searchResult.filteredSortedTemplates} isMobile={isMobile} />
              </div>
            ) : null}
            <FilterSortDialogMobile
              filterSortObj={filterSortValue}
              isBrowsePage={false}
              open={isOpenFilterSortDialog}
              onDialogClose={handleFilterSortDialogClose}
              languages={filterLanguages}
              categories={filterCagetories}
              trades={filterTrades}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SearchLayout;
